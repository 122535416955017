import React from 'react';
import css from './SectionStay.module.css';
import image from '../../../assets/Images/stay.png';
import { NamedLink } from '../../../components';
import { FormattedMessage } from 'react-intl';
const SectionStay = ({ intl }) => {
  return (
    <div className={css.root}>
      <div className={css.container}>
        <div className={css.left}>
          <img src={image} className={css.image} alt="stay" />
          <p className={css.imageText}>
            {intl.formatMessage({ id: 'SectionStay.SectionStay.imageText' })}
          </p>
        </div>
        <div className={css.right}>
          <div>
            <h2 className={css.heading}>
              {intl.formatMessage({ id: 'SectionStay.SectionStay.lingoStayLabel' })}
            </h2>
            <p className={css.text}>
              {intl.formatMessage({ id: 'SectionStay.SectionStay.lingoStaySubheading' })}
            </p>
          </div>
          <NamedLink
            name="SearchPage"
            to={{
              search: '?pub_category=category1',
            }}
            className={css.heroButton}
          >
            {intl.formatMessage({ id: 'SectionStay.SectionStay.findStayLabel' })}
          </NamedLink>
          <p className={css.textBelow}>
            <FormattedMessage
              id="SectionStay.SectionStay.subheadingOne"
              values={{ span: msg => <span className={css.changeColor}>{msg}</span> }}
            />
            <br />{' '}
            <FormattedMessage
              id="SectionStay.SectionStay.subheadingTwo"
              values={{ span: msg => <span className={css.changeColor}>{msg}</span> }}
            />
            <br />{' '}
            <FormattedMessage
              id="SectionStay.SectionStay.subheadingThree"
              values={{ span: msg => <span className={css.changeColor}>{msg}</span> }}
            />
          </p>
        </div>
      </div>
    </div>
  );
};

export default SectionStay;
