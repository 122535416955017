import React from 'react';
import css from './SectionActivities.module.css';
import image from '../../../assets/Images/activity.png';
import { NamedLink } from '../../../components';
import { FormattedMessage } from 'react-intl';
const SectionActivities = ({ intl }) => {
  return (
    <div className={css.root}>
      <div className={css.container}>
        <div className={css.right}>
          <div>
            <h2 className={css.heading}>
              {intl.formatMessage({
                id: 'SectionActivities.SectionActivities.lingoActivityHeading',
              })}
            </h2>
            <p className={css.text}>
              {intl.formatMessage({
                id: 'SectionActivities.SectionActivities.lingoActivitySubheading',
              })}
            </p>
          </div>
          <NamedLink
            name="SearchPage"
            to={{
              search: '?pub_category=activity',
            }}
            className={css.heroButton}
          >
            {intl.formatMessage({
              id: 'SectionActivities.SectionActivities.findLingoActivityLabel',
            })}
          </NamedLink>
          <p className={css.textBelow}>
            <FormattedMessage
              id="SectionActivities.SectionActivities.lingoActivitySubHeadingOne"
              values={{ span: msg => <span className={css.changeColor}>{msg}</span> }}
            />
            <br />
            <FormattedMessage
              id="SectionActivities.SectionActivities.lingoActivitySubheadingTwo"
              values={{ span: msg => <span className={css.changeColor}>{msg}</span> }}
            />{' '}
            <br />
            <FormattedMessage
              id="SectionActivities.SectionActivities.lingoActivitySubHeadingThree"
              values={{ span: msg => <span className={css.changeColor}>{msg}</span> }}
            />
          </p>
        </div>
        <div className={css.left}>
          <img src={image} className={css.image} alt="stay" />
          <p className={css.imageText}>
            {intl.formatMessage({ id: 'SectionActivities.SectionActivities.imageText' })}
          </p>
        </div>
      </div>
    </div>
  );
};

export default SectionActivities;
