import React from 'react';
import css from './SectionStayListing.module.css';
import { IconSpinner, ListingCard } from '../../../components';
const SectionStayListing = props => {
  const { listings = [], listingsLoading, listingsError, heading, search } = props;

  // Panel width relative to the viewport
  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
  ].join(', ');
  if (listingsLoading) {
    return (
      <div>
        <h1 className={css.heading}>{heading}</h1>
        <div className={css.root}>
          <div className={css.container}>
            <IconSpinner />
          </div>
        </div>
      </div>
    );
  }

  if (listingsError) {
    return (
      <div>
        <h1 className={css.heading}>{heading}</h1>
        <div className={css.root}>
          <div className={css.container}>
            <p className={css.error}>
              {listingsError.message
                ? listingsError.message
                : 'Something went wrong. Please refresh the page.'}
            </p>
          </div>
        </div>
      </div>
    );
  }
  if (listings && listings?.length === 0) {
    return null;
  }
  return (
    <div>
      <h1 className={css.heading}>{heading}</h1>
      <div className={css.root}>
        <div className={css.listingCards}>
          {listings.map(l => (
            <ListingCard
              className={css.listingCard}
              key={l.id.uuid}
              listing={l}
              renderSizes={cardRenderSizes}
              isLandingPage={true}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SectionStayListing;
