import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import config from '../../config';
import {
  Page,
  SectionHero,
  SectionHowItWorks,
  SectionLocations,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  FooterUI,
  SectionHeroUI,
  LandingPageSectionHeading,
  SectionStudent,
  SectionTeacher,
  SectionWhatMakes,
  SearchBar,
  SectionConnectingStudents,
} from '../../components';
import { TopbarContainer } from '../../containers';

import facebookImage from '../../assets/saunatimeFacebook-1200x630.jpg';
import twitterImage from '../../assets/saunatimeTwitter-600x314.jpg';
import css from './LandingPage.module.css';
import { stringifyDateToISO8601 } from '../../util/dates';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { translateText } from '../../util/api';
import { types as sdkTypes } from '../../util/sdkLoader';
import SectionStay from './SectionStay/SectionStay';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import SectionStayListing from './SectionStayListing/SectionStayListing';
import SectionActivities from './SectionActivities/SectionActivities';
import SectionPrivate from './SectionPrivate/SectionPrivate';
const { LatLng, LatLngBounds, Money } = sdkTypes;
export const LandingPageComponent = props => {
  const {
    history,
    intl,
    location,
    scrollingDisabled,
    listings,
    listingsLoading,
    listingsError,
    activitiesLoading,
    activitiesError,
    activities,
    privateTours,
    privateToursLoading,
    privateToursError,
  } = props;

  const onSearchSubmit = values => {
    const { location, startDate, endDate, guests } = values;
    const startDay = stringifyDateToISO8601(startDate);
    const endDay = stringifyDateToISO8601(endDate);
    const dates = startDate || endDate ? `${startDay},${endDay}` : null;
    const { search, selectedPlace } = location ?? {};
    const { origin, bounds } = selectedPlace ?? {};
    // const newBounds = bounds
    //   ? new LatLngBounds(
    //       new LatLng(bounds.ne.lat + 2, bounds.ne.lng + 2),
    //       new LatLng(bounds.sw.lat - 2, bounds.sw.lng - 2)
    //     )
    //   : null;
    const originMaybe = config.sortSearchByDistance ? { origin } : {};
    let searchParams = {
      ...originMaybe,
      address: search,
      bounds,
      dates,
      pub_guestFilter: guests,
    };
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
  };
  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;
  const isAuth = useSelector(state => state.Auth.isAuthenticated);
  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <div className={css.greenShadow}></div>
      <LayoutSingleColumn>
        <LayoutWrapperTopbar isLandingPage={true}>
          <TopbarContainer currentPage="LandingPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <SectionHero history={history} location={location} intl={intl} />
          {/* <div className={css.heroContainerNew}>
            <SectionHeroUI
              className={css.heroNew}
              history={history}
              location={location}
              onSearchSubmit={onSearchSubmit}
            />
            <div className={css.landingPageSearch}>
              <SearchBar onSubmit={onSearchSubmit} />
            </div>
          </div> */}
          {/* <button
            type="button"
            onClick={() => {
              translateText();
            }}
          >
            Click
          </button> */}
          <div className={css.main}>
            <h1 className={css.heading}>
              {intl.formatMessage({ id: 'LandingPage.LandingPage.youWannaLingoLabel' })}
            </h1>
            <SectionActivities intl={intl} />
            <SectionStayListing
              intl={intl}
              listings={activities}
              listingsLoading={activitiesLoading}
              listingsError={activitiesError}
              heading={intl.formatMessage({
                id: 'LandingPage.LandingPage.featuredLingoActivityLabel',
              })}
            />
            {/* <br />
            <br /> */}
            <SectionStay intl={intl} />
            <SectionStayListing
              intl={intl}
              listings={listings}
              listingsLoading={listingsLoading}
              listingsError={listingsError}
              heading={intl.formatMessage({ id: 'LandingPage.LandingPage.featuredLingoStayLabel' })}
            />

            <SectionPrivate intl={intl} />
            <SectionStayListing
              intl={intl}
              listings={privateTours}
              listingsLoading={privateToursLoading}
              listingsError={privateToursError}
              heading={intl.formatMessage({
                id: 'LandingPage.LandingPage.featuredLingoPrivateListingLabel',
              })}
            />
          </div>

          {/* <ul className={css.sections}>
            <li className={css.section}>
              <div className={css.sectionContent}>
                <SectionStay intl={intl} /> */}
          {/* <LandingPageSectionHeading /> */}
          {/* </div>
            </li>
            <li className={css.section}>
              <div className={css.sectionContent}>
                <SectionStayListing
                  intl={intl}
                  listings={listings}
                  listingsLoading={listingsLoading}
                  listingsError={listingsError}
                /> */}
          {/* <SectionStudent isAuth={isAuth} intl={intl} /> */}
          {/* </div>
            </li> */}
          {/* <li className={css.section}>
              <div className={css.desktopOnly}>
                <div className={css.sectionContent}>
                  <SectionTeacher isAuth={isAuth} intl={intl} />
                </div>
              </div>
            </li>
            <li className={css.sectionShadow}>
              <div className={css.greenShadow2}></div>
              <div className={css.sectionContentLastChild}>
                <SectionWhatMakes intl={intl} />
              </div>
            </li>
            <li className={css.section}>
              <div className={css.sectionContentLastChild}>
                <SectionConnectingStudents intl={intl} />
              </div>
            </li> */}
          {/* </ul> */}
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <FooterUI />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool, object } = PropTypes;

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    listingIds,
    listingsLoading,
    listingsError,
    activitiesLoading,
    activitiesError,
    activityIds,
    privateToursLoading,
    privateToursError,
    privateTourIds,
  } = state.LandingPage;
  const listings = listingIds?.map(t => {
    const ref = { id: t, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  });
  const activities = activityIds?.map(t => {
    const ref = { id: t, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  });
  const privateTours = privateTourIds?.map(t => {
    const ref = { id: t, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  });
  return {
    scrollingDisabled: isScrollingDisabled(state),
    listings,
    listingsLoading,
    listingsError,
    activitiesLoading,
    activitiesError,
    activities,
    privateTours,
    privateToursLoading,
    privateToursError,
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withRouter,
  connect(mapStateToProps),
  injectIntl
)(LandingPageComponent);

export default LandingPage;
